declare global {
  interface Window {
    gtag: any;
    fbq: any;
  }
}

// https://developers.google.com/analytics/devguides/collection/gtagjs/pages
export const pageview = (url: string) => {
  if (process.env.NEXT_PUBLIC_GA_TRACKING_ID) {
    window.gtag('config', process.env.NEXT_PUBLIC_GA_TRACKING_ID, {
      page_path: url,
    });
  }

  if (process.env.NEXT_PUBLIC_FACEBOOK_PIXEL_ID) {
    window.fbq('track', 'PageView');
  }
};

// https://developers.google.com/analytics/devguides/collection/gtagjs/events
export const event = (e: { action: string; category: string; label: string; value: string }) => {
  if (process.env.NEXT_PUBLIC_GA_TRACKING_ID) {
    window.gtag('event', e.action, {
      event_category: e.category,
      event_label: e.label,
      value: e.value,
    });
  }
};
