import React, { useEffect } from 'react';

import { AppProps } from 'next/app';
import { useRouter } from 'next/router';

import { pageview } from '../utils/Analytics';
import { init } from '../utils/Sentry';

import '../styles/main.css';

init();

const CustomApp = (props: AppProps) => {
  const router = useRouter();

  useEffect(() => {
    const handleRouteChange = (url: string) => {
      pageview(url);
    };
    router.events.on('routeChangeComplete', handleRouteChange);
    return () => {
      router.events.off('routeChangeComplete', handleRouteChange);
    };
  }, [router.events]);

  // Workaround for https://github.com/vercel/next.js/issues/8592
  const { err } = props as any;

  return (
    // eslint-disable-next-line react/jsx-props-no-spreading
    <props.Component {...props.pageProps} err={err} />
  );
};

export default CustomApp;
